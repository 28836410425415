<template>
  <div class="about" >
    <div v-loading="loading" :element-loading-text="loadingtext">
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="110px"
          class="demo-ruleForm">
        <el-form-item label="转出人" prop="mode">
          <el-select
              style="width: 100%"
              v-model="ruleForm.out_user"
              filterable
              remote
              clearable
              reserve-keyword
              placeholder="输入姓名、手机号查询"
              :remote-method="remoteMethod"
              :loading="login">
            <el-option
                v-for="item in memberArr"
                :key="item.member_id"
                :label="item.name+'-'+item.mobile_phone"
                :value="item.member_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="转入人" prop="mode">
          <el-select
              style="width: 100%"
              v-model="ruleForm.into_user"
              filterable
              remote
              clearable
              reserve-keyword
              placeholder="输入姓名、手机号查询"
              :remote-method="remoteMethod2"
              :loading="login">
            <el-option
                v-for="item in memberArr2"
                :key="item.member_id"
                :label="item.name+'-'+item.mobile_phone"
                :value="item.member_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"  :loading="login" @click="okAdd('ruleForm',2)">确定</el-button>
          <!--          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>-->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { getArea } from "@/api/common";
import * as axios from "@/api/skuApi"//本页面接口
export default {
  name: 'Grade',
  data(){
    return{
      memberArr:[],
      memberArr2:[],
      loadingtext:'正在批量修改中请勿关闭此页面！！',
      skuValName:'',
      loading:false,
      login:false,
      drawer:false,
      tableData:[],
      areaArr:[],//城市
      page:1,
      limit:10,
      total:0,
      keywords:'',
      ruleForm:{
        out_user:'',
        into_user:'',
      },
      modeArr:[
        {
          id:0,
          name:'新申请',
        },
        {
          id:1,
          name:'处理中',
        },
        {
          id:2,
          name:'完成',
        },
        {
          id:3,
          name:'失败',
        },
      ],
      rules:{
        name: [
          { required: true, message: '请填写名称', trigger: 'blue' },
        ],
        start_date: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        end_date: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
    }
  },
  created() {
    this.remoteMethod()
    this.remoteMethod2()
  },
  methods:{
    /*远程搜索*/
    remoteMethod(query) {
        this.login = true;
        let form={
          page:1,
          limit:500,
          w_type:'base',
          keywords:query
        }
        axios.getSkuData(form,'/api/admin/emp').then((res)=>{
          this.memberArr=res.data.data
          this.login = false;
        }).catch((err)=>{
          this.login = false;
        })
    },
    /*远程搜索*/
    remoteMethod2(query) {
        this.login = true;
        let form={
          page:1,
          limit:500,
          w_type:'base',
          keywords:query
        }
        axios.getSkuData(form,'/api/admin/emp').then((res)=>{
          this.memberArr2=res.data.data
          this.login = false;
        }).catch((err)=> {
          this.login = false;
        })
    },
    onTable(){
      this.loading = true;
      let that=this
      axios.postSkuData(null,'/api/admin/member/member_refarral/batchEditCrontab').then((res)=>{
        if(res.data.total_count>0){
          this.loadingtext='正在批量修改中请勿关闭此页面！！--'+res.data.pro2
         setTimeout(()=>{
          that.onTable()
         },1000)
        }else {
          setTimeout(()=>{
            that.loading = false;
          },1000)
        }
      }).catch((err)=> {
        this.loading = false;
        this.$message.error('服务器报错！！请联系管理员')
      })
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('批量归属修改？, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            axios.postSkuData(this.ruleForm,'/api/admin/member/member_refarral/batchEdit').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                  that.login=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });
          });

        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },

  }
}
</script>
<style lang="less" >
</style>